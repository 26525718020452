<template>
  <div>
    <CCard>
      <CCardHeader>
        <h2>Ad-hoc Test Runner{{ clientCode ? ' - ' + clientCode : '' }}</h2>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <p>
              Enter the information below to run ad-hoc tests against the chosen
              tax engine.
            </p>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6">
            <CInput
              label="Test Run Name:"
              :value.sync="runName"
              placeholder="Enter test run name"
              horizontal
              required
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6">
            <CSelect
              label="Tax Engine:"
              :options="taxEngines"
              :value.sync="taxEngine"
              :disabled="taxEngines.length < 2"
              placeholder="Choose"
              horizontal
              :required="taxEngines.length > 1"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6">
            <CSelect
              label="Partition:"
              :options="partitions"
              :value.sync="partition"
              placeholder="Choose"
              horizontal
              :required="isSbx"
              :disabled="!isSbx"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6">
            <DatePicker
              v-model="transactionDate"
              label="Transaction Date:"
              opens="right"
              horizontal
              required
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6">
            <CInput
              label="URL:"
              :value.sync="taxEngineUrl"
              placeholder="https://"
              horizontal
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6">
            <CInput
              label="Username:"
              :value.sync="taxEngineUsername"
              placeholder="Enter Username"
              horizontal
              required
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6">
            <CInput
              label="Password:"
              :value.sync="taxEnginePassword"
              placeholder="Enter Password"
              type="password"
              horizontal
              required
            />
          </CCol>
        </CRow>
        <CRow v-if="!testSuccess">
          <CCol>
            <MButton
              color="info"
              :disabled="!canTestConnection"
              :active="isCurrentlyTesting"
              class="ml-2"
              @click="testConnection"
              >Test Connection</MButton
            >
          </CCol>
        </CRow>
        <template v-else>
          <CRow>
            <CCol>
              Download a copy of the
              <FileLink
                :file="{
                  fileLink: templateLink,
                  fileTitle: 'Custom Test Transaction Workbook Template',
                }"
                >Custom Test Transaction Template
              </FileLink>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <FileUploadBlock
                :file="adhocFile"
                :submit-enabled="canSubmit"
                @update="setAdhocFile"
                @upload="handleUpload"
              />
            </CCol>
          </CRow>
        </template>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import apiConfig from '@/api/config';
import FileUploadBlock from '@/components/FileUploadBlock';
import TestsAPI from '@/api/tests-api';
import DatePicker from '@/components/DatePicker';
import MButton from '@/components/MButton';
import FileLink from '@/components/FileLink';
import { VUE_APP_ALLOW_VTX, VUE_APP_ALLOW_SBX } from '@/utils/env';

const TAX_ENGINES = [
  {
    label: 'ONESOURCE',
    value: 'SBX',
    enabled: VUE_APP_ALLOW_SBX,
  },
  {
    label: 'Vertex',
    value: 'VTX',
    enabled: VUE_APP_ALLOW_VTX,
  },
];

const ENABLED_TAX_ENGINES = TAX_ENGINES.filter((o) => o.enabled).map((o) => {
  return Object.freeze({
    value: o.value,
    label: o.label,
  });
});

const TEMPLATE_LINKS = {
  SBX: '/api/files/templates/IDTSbx_90_CustomTestTrx.xltx',
  VTX: '/api/files/templates/IDTVtx_90_CustomTestTrx.xltx',
};

export default {
  name: 'AdHocTestRunner',
  components: {
    FileUploadBlock,
    DatePicker,
    MButton,
    FileLink,
  },
  props: {
    clientCode: {
      type: String,
      required: true,
    },
  },
  data() {
    let taxEngine = null;
    if (ENABLED_TAX_ENGINES.length === 1) {
      taxEngine = ENABLED_TAX_ENGINES[0].value;
    }
    return {
      taxEngine,
      adhocFile: null,
      testSuccess: false,
      isCurrentlyTesting: false,
      transactionDate: new Date(),
      runName: null,
      partition: 'PRD',
      connection: {
        taxEngineUrl: null,
        taxEngineType: null,
        username: null,
        password: null,
      },
    };
  },
  computed: {
    partitions() {
      return Object.freeze([
        {
          label: 'Production',
          value: 'PRD',
        },
        {
          label: 'Development',
          value: 'DEV',
        },
        {
          label: 'Lab / Test',
          value: 'LAB',
        },
      ]);
    },
    isSbx() {
      return (this.taxEngine || '').toLowerCase() === 'sbx';
    },
    templateLink() {
      if (!this.taxEngine) {
        return null;
      } else {
        return TEMPLATE_LINKS[this.taxEngine];
      }
    },
    taxEngines() {
      return ENABLED_TAX_ENGINES;
    },
    canSubmit() {
      return (
        !!this.runName &&
        !!this.adhocFile &&
        !!this.clientCode &&
        !!this.taxEngine &&
        !!this.taxEngineUrl &&
        !!this.taxEngineUsername &&
        !!this.taxEnginePassword
      );
    },
    hasConnection() {
      return !!this.connection;
    },
    hasTaskEngineUrl() {
      if (this.hasConnection) {
        return !!this.connection.taxEngineUrl;
      }
      return false;
    },
    hasUsername() {
      if (this.hasConnection) {
        return !!this.connection.username;
      }
      return false;
    },
    hasPassword() {
      if (this.hasConnection) {
        return !!this.connection.password;
      }
      return false;
    },
    authIsRequired() {
      if (this.taxEngine && this.taxEngine.toLowerCase() === 'sbx') {
        return this.connection.taxEngineType === 'SABRIX_SOAP';
      }

      return true;
    },
    canTestConnection() {
      if (!this.hasTaskEngineUrl) {
        return false;
      }

      if (this.authIsRequired) {
        return this.hasUsername && this.hasPassword;
      }

      return true;
    },
    taxEngineUrl: {
      get() {
        return this.hasConnection ? this.connection.taxEngineUrl : null;
      },
      set(v) {
        this.connection.taxEngineUrl = v;
      },
    },
    taxEngineUsername: {
      get() {
        return this.hasConnection ? this.connection.username : null;
      },
      set(v) {
        this.connection.username = v;
      },
    },
    taxEnginePassword: {
      get() {
        return this.hasConnection ? this.connection.password : null;
      },
      set(v) {
        this.connection.password = v;
      },
    },
    formMode() {
      return this.taxEngine ? this.taxEngine.toLowerCase() : null;
    },
  },
  methods: {
    setAdhocFile(file) {
      this.adhocFile = file;
    },
    handleUpload() {
      var payload = new FormData();
      payload.append('file', this.adhocFile);
      payload.append('url', this.connection.taxEngineUrl);
      payload.append('username', this.connection.username);
      payload.append('password', this.connection.password);
      payload.append('clientCode', this.clientCode);
      payload.append('runName', this.runName);
      payload.append('partition', this.partition);

      TestsAPI.executeAdHoc(this.taxEngine.toLowerCase(), payload)
        .then((r) => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Test Run Scheduled',
              html:
                'Tests successfully imported.<br><br>' +
                'You will now be sent to the workflow overview to monitor progress.',
              showCancelButton: false,
            })
            .then(() => {
              this.$router.push({
                name: 'view-workflow',
                params: { id: r.workflowId },
              });
            });
          //workflowId - id of the workflow started for this
          //testRunId - test run id of the test
          //count - the number of transactions executed in this test
        })
        .catch((e) => {
          let msg = null;
          let messages = e.response.data.messages;
          if (messages) {
            if (messages.length > 1) {
              msg =
                'There were multiple errors.  Please check your data and try again.';
            } else if (messages.length > 0) {
              msg = messages[0];
            }
          }
          this.$swal.fire({
            title: 'Error',
            icon: 'error',
            text: msg || 'Errors occurred submitting the request',
          });
        });
    },
    testConnection() {
      if (this.isCurrentlyTesting) {
        return;
      }
      this.isCurrentlyTesting = true;
      let info = {
        url: this.connection.taxEngineUrl,
        username: this.connection.username,
        password: this.connection.password,
      };

      var connTestUrl = '/api/vtx/testing/test-connection';
      if (this.formMode !== 'vtx') {
        connTestUrl = '/api/sbx/testing/test-connection';
      }

      apiConfig.client
        .post(connTestUrl, info)
        .then((res) => {
          this.testSuccess = true;
          this.$swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'We were successfully able to connect to the Tax Engine.',
          });
        })
        .catch((err) => {
          this.testSuccess = false;
          let message =
            'Unable to connect to the Tax Engine with the provided connection info.';
          if (err.response.data && err.response.data.message) {
            message = err.response.data.message;
          }
          this.$swal.fire({
            title: 'ERROR',
            text: message,
            icon: 'error',
          });
        })
        .finally(() => {
          this.isCurrentlyTesting = false;
        });
    },
  },
};
</script>
